import styled from 'styled-components';
import { space, color } from 'styled-system';

export const htmlCss = ({ theme }) => `
  line-height: 1.5;
  color: ${theme.color['secondary']};
  ${color};
  a:link {
    color: ${theme.colors.chestnutRose};
    text-decoration: underline;
    font-weight: 500;
  }
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  ul,
  ol {
    margin-left: 1rem;
  }
  /* custom list bullets */
  ul {
    li::before {
      content: '•';
      color: ${theme.colors.chestnutRose};
      display: inline-block;
      width: 1em;
    }
  }
  h1{font-size: 2.5rem;}
  h2{font-size: 2rem;}
  h3{font-size: 1.75rem;}
  h4{font-size: 1.5rem;}
  h5{font-size: 1.25rem;}
  h6{font-size: 1rem;}
`;

const Html = styled.div`
  ${space}
  ${props => htmlCss(props)}
  ${color}
`;

export default Html;
