import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import sanitizeHtml from 'sanitize-html';
import Layout from '..';
import Container from '../../Container';
import Text from '../../Text';
import Html from '../Html';
import SEO from '../../seo';

const Header = styled(Box).attrs(() => ({
  py: 5,
}))`
  background: ${({ theme }) => theme.colors.valhalla};
  color: white;
`;

const Heading = styled(Text).attrs(() => ({
  as: 'h1',
  fontSize: 6,
  color: 'white',
  textAlign: 'center',
}))``;

const Content = styled(Container).attrs(() => ({
  py: [3, 4, 5],
}))``;
Content.defaultProps = {
  maxWidth: 768,
};

const Lead = styled(Text).attrs(() => ({
  as: 'p',
  fontSize: 2,
  py: 3,
  variant: 'secondary',
}))``;

const PageLayout = ({
  title,
  meta_title,
  meta_og_image,
  meta_description,
  lead,
  excerpt,
  content,
  children,
  maxWidth,
  noHeader,
}) => {
  const html = content;
  const metaDescription =
    meta_description ||
    sanitizeHtml(lead || excerpt || content, {
      allowedTags: [],
      allowedAttributes: {},
    });
  return (
    <Layout>
      <SEO
        ogImage={meta_og_image}
        title={meta_title || title}
        description={metaDescription}
      />
      {!noHeader && (
        <Header>
          <Container>
            <Heading>{title}</Heading>
          </Container>
        </Header>
      )}
      <Content maxWidth={maxWidth}>
        {lead && <Lead>{lead}</Lead>}
        {content && <Html dangerouslySetInnerHTML={{ __html: html }} />}
        {children}
      </Content>
    </Layout>
  );
};

PageLayout.defaultProps = {
  title: 'Title of the page',
};

PageLayout.propTypes = {};

export default PageLayout;
