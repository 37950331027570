import React, { useRef } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Card from '../../Card';
import Text from '../../Text';
import { format } from 'date-fns';
import Button from '../../Button';
import Link from '../../Link';
import Html from '../../Layout/Html';
import PropertyPair from './PropertyPair';

const Name = styled(Text).attrs(() => ({
  as: 'h3',
  color: 'valhalla',
  fontSize: 4,
}))``;

const DateTime = styled(Text).attrs(() => ({
  color: 'chestnutRose',
  as: 'time',
}))`
  text-transform: uppercase;
`;

const EventCard = ({
  uid,
  show_in_student_calendar,
  event_name,
  start_date,
  end_date,
  start_time,
  end_time,
  vanue_name,
  street_address,
  city,
  state,
  country,
  event_description,
  organizer_name,
  organizer_phone_number,
  organizer_email_address,
  isCollapsed,
  event_organizer_website,
  cost,
  ticket_url,
  event_shopify_product,
}) => {
  const ref = useRef();
  const dateFormat = 'MMM DD YYYY, (ddd)';
  const startDate = `${format(start_date, dateFormat)} @ ${start_time}`;
  const endDate = `${format(end_date, dateFormat)} @ ${end_time}`;
  const location = `${city}${
    country === 'United States of America' || country === 'United States'
      ? state
        ? `, ${state}`
        : ''
      : country
      ? `, ${country}`
      : ''
  }`;

  return (
    <Card p={4} ref={ref}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          {!isCollapsed && <DateTime mb={1}>{startDate}</DateTime>}
          <Name mb={2}>{event_name}</Name>
          {!isCollapsed && <Text variant="secondary">{location}</Text>}
        </Box>
        {isCollapsed ? (
          <>
            {event_shopify_product?.id && (
              <Link to={`/events/register?eventUid=${uid}`}>
                <Button variant="secondary">Register</Button>
              </Link>
            )}
            {ticket_url && (
              <Link to={ticket_url}>
                <Button variant="secondary">Tickets</Button>
              </Link>
            )}
          </>
        ) : (
          <Link to={`/event/${uid}`}>
            <Button variant="white">View</Button>
          </Link>
        )}
      </Flex>
      {isCollapsed && (
        <Box mt={5}>
          <Flex flexDirection="column" my={-2}>
            <PropertyPair py={2} property="When">
              <Text color="chestnutRose" style={{ textTransform: 'uppercase' }}>
                {startDate} – {endDate}
              </Text>
            </PropertyPair>
            <PropertyPair py={2} property="Where">
              <Text variant="secondary">{vanue_name}</Text>
              <Text
                variant="secondary"
                dangerouslySetInnerHTML={{ __html: street_address }}
              />
              <Text variant="secondary">{location}</Text>
            </PropertyPair>
            {!show_in_student_calendar && (
              <PropertyPair py={2} property="Cost">
                <Text variant="secondary">{cost}</Text>
              </PropertyPair>
            )}
            {(organizer_name ||
              organizer_phone_number ||
              organizer_email_address) && (
              <PropertyPair py={2} property="More info">
                <Text variant="secondary">{organizer_name}</Text>
                <Text variant="secondary">{organizer_phone_number}</Text>
                {organizer_email_address && (
                  <div>
                    <a href={`mailto:${organizer_email_address}`}>Email</a>
                  </div>
                )}
                {event_organizer_website && (
                  <div>
                    <a
                      href={event_organizer_website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Website
                    </a>
                  </div>
                )}
              </PropertyPair>
            )}
          </Flex>
          <Html
            mt={5}
            dangerouslySetInnerHTML={{
              __html: event_description,
            }}
          />
        </Box>
      )}
    </Card>
  );
};

EventCard.propTypes = {};

export default EventCard;
