import React from 'react';
import { Flex, Box } from 'rebass';
import Text from '../../Text';

const PropertyPair = ({ property, children, ...props }) => {
  return (
    <Flex {...props}>
      <Text
        css={{
          textTransform: 'uppercase',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant="tertiary"
        textAlign="right"
        title={property}
        width={[2 / 10, null, 2 / 8]}
      >
        {property}:
      </Text>
      <Box ml={2} width={[8 / 10, null, 6 / 8]} variant="secondary">
        {children}
      </Box>
    </Flex>
  );
};

PropertyPair.propTypes = {};

export default PropertyPair;
