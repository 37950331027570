import { useMemo, useContext } from 'react';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { PreviewContext } from '../components/Preview';

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== 'undefined';

/**
 * Prismic preview hook
 * @param {object} staticData Data object from Gatsby page
 */
export default function usePreviewData(staticData) {
  const { previewData } = useContext(PreviewContext);
  return useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !previewData) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData,
    });
  }, [staticData]);
}
