const resolvePrismicEvent = node => {
  const { data } = node;
  return {
    id: node.id,
    uid: node.uid,
    ...data,
    start_date: new Date(`${data.start_date}T12:00:00`),
    end_date: new Date(`${data.end_date}T12:00:00`),
    show_in_student_calendar: data.show_in_student_calendar === 'Yes',
    on_campus_event: data.on_campus_event === 'Yes',
    virtual_event: data.virtual_event === 'Yes',
    event_name: data.event_name?.text,
    event_description: data.event_description?.html || undefined,
    event_organizer_website: data.event_organizer_website?.url || undefined,
    ticket_url: data.ticket_url?.url || undefined,
    street_address: data.street_address?.html || undefined,
  };
};

export default resolvePrismicEvent;
