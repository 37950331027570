import React from 'react';
import PageLayout from '../../Layout/Page';
import EventCard from '../Card';
import resolvePrismicEvent from '../Shell/prismicResolver';
import usePreviewData from '../../../utils/usePreviewData';
import sanitize from 'sanitize-html';

const EventPage = ({ pageContext }) => {
  const liveData = usePreviewData({ prismicEvent: pageContext.data });
  const event = resolvePrismicEvent(liveData.prismicEvent);
  return (
    <PageLayout
      title={event.event_name}
      meta_description={sanitize(event.event_description, { allowedTags: [] })}
    >
      <EventCard {...event} isCollapsed />
    </PageLayout>
  );
};

export default EventPage;
